import { LaunchDarklyClientSide } from '@repairer-frontend/client-feature-flags';
import {
    ROOT_CONFIG_LOCAL_STORAGE_KEY,
    generateConfig,
    getApiBaseUrl,
} from '@repairer-frontend/root-config';
import { navigateToUrl, registerApplication, start } from 'single-spa';
import {
    constructApplications,
    constructLayoutEngine,
    constructRoutes,
} from 'single-spa-layout';
import { header, sidebar } from './loaders';
import './styles/css/main.scss';

import { buildPtusFrontendUrl } from '@repairer-frontend/ptus-frontend-url-builder';
import { PATHS } from '@repairer-frontend/root-config';
import { ShouldDisableAnimations } from '@repairer-frontend/shared/client/client-app-test-support';
import { UserData } from '@repairer-frontend/user-types';
import { LayoutProvider } from './LayoutProvider';
import { login } from './auth/auth';
import microfrontendLayout from './microfrontend-layout.html?raw';
import { configureNewrelic } from './newrelic/configuration';

const REPAIRER_ROLES = new Set([
    'CompanyAdmin',
    'Estimator',
    'RepairerCompanyAdmin',
    'FeedbackManager',
    'RatingViewer',
    'RepairerAuditor',
    'RepairerPaymentsManager',
    'PartsOrderer',
]);

// Need to set up this and send over to the worker from here as workers don't have access to the window scope
let apiGatewayUrl = getApiBaseUrl();
let isNonProdConfiguration = true;
if (window.location.hostname === 'devrepairer.partstrader.io') {
    apiGatewayUrl = 'https://devrepairerapi.partstrader.io';
}

// Root development
if (window.location.hostname.includes('testrepairer-beh.pages.dev')) {
    apiGatewayUrl = 'https://testrepairerapi.testpartstrader.us.com';
}

// test environment
if (window.location.hostname === 'testrepairer.partstrader.io') {
    apiGatewayUrl = 'https://testrepairerapi.testpartstrader.us.com';
}

// prod test
if (window.location.hostname === 'repairer.partstrader.us.com') {
    apiGatewayUrl = 'https://repairerapi.partstrader.us.com';
    isNonProdConfiguration = false;
}

if (isNonProdConfiguration) {
    if (!window['Cypress']) {
        // add the import map override dev tool
        const importMapOverrideDevTools = document.createElement(
            'import-map-overrides-full',
        );
        importMapOverrideDevTools['dev-libs'] = 'dev-libs';
        document.body.append(importMapOverrideDevTools);
    }

    // add field to the window object required for Cypress code coverage
    window['__coverage__'] = window['__coverage__'] || {};
}

configureNewrelic({
    accountId: '2839186',
    trustKey: '739791',
    agentId: '601448608',
    licenseKey: 'NRBR-4ed05250c3c6b0af49e',
    applicationId: '601448608',
});

//Timeout to make sure the worker is ready before sending the message

const attemptRedirect = () => {
    try {
        window.location.href = `${apiGatewayUrl}/fss/auth/login?returnUrl=${window.location.href}`;
    } catch (error) {
        console.error(error);
        navigateToUrl('error');
    }
};

/**
 * Dev only function to inject the vite refresh snippet into the page.
 * @param baseUrl
 */
const injectViteRefreshSnippet = (baseUrl: string) => {
    const scriptElement = document.createElement('script');
    scriptElement.type = 'module';
    scriptElement.textContent = `
            import { injectIntoGlobalHook } from "${baseUrl}/@react-refresh";
            injectIntoGlobalHook(window);
            window.$RefreshReg$ = () => {};
            window.$RefreshSig$ = () => (type) => type;
        `;
    document.head.append(scriptElement);
};

const disableAnimationsIfRunningTests = () => {
    if (ShouldDisableAnimations()) {
        document.body.classList.add('no-animations');
    }
};

/**
 * Initializes the root config settings with the provided user data.
 * @param userData The user data used for initialization.
 */
const initialize = async (userData: UserData) => {
    const config = await generateConfig(
        userData,
        import.meta?.env?.VITE_LaunchDarkly_repairer_vnext_Client_Sdk_Key,
    );
    const { user = null } = config;

    window.localStorage.setItem(
        ROOT_CONFIG_LOCAL_STORAGE_KEY,
        JSON.stringify(config),
    );

    await LaunchDarklyClientSide({
        envKey: config.launchDarklyClientId,
        context: {
            kind: 'user',
            key: `c-${user.company_displayid}`,
        },
    });

    const data = {
        loaders: {
            header,
            sidebar,
        },
        props: {
            config: { baseUrl: '/', ...config },
            handleError: (error: Error) => {
                console.error('Error in root', error);
            },
        },
    };

    new LayoutProvider();

    if (window.location.pathname === '/') {
        navigateToUrl(`${PATHS.jobs}`);
    }

    const importMap = await fetch('/importmap.json').then((res) => res.json());

    const routes = constructRoutes(microfrontendLayout, data);
    const applications = constructApplications({
        routes,
        loadApp({ name }: { name: string }) {
            const pathToApp =
                localStorage.getItem(`import-map-override:${name}`) ??
                importMap.imports[name];

            // enable HMR for any apps that are running locally
            if (
                pathToApp.includes('http://localhost') &&
                process.env.NODE_ENV !== 'localdev'
            ) {
                injectViteRefreshSnippet(new URL(pathToApp).origin);
            }

            return import(/* @vite-ignore */ pathToApp);
        },
    });
    const layoutEngine = constructLayoutEngine({
        routes,
        applications,
    });

    for (const app of applications) {
        registerApplication(app);
    }

    layoutEngine.activate();
    start();
    disableAnimationsIfRunningTests();
};

// eslint-disable-next-line unicorn/prefer-top-level-await
login().then((userData) => {
    if (!userData) {
        attemptRedirect();
        return;
    }
    if (userData?.roles.some((role) => REPAIRER_ROLES.has(role))) {
        initialize(userData);
    } else {
        window.location.href = buildPtusFrontendUrl('');
    }
});
