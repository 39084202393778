export const header = `<header class="header"><section class="header-loader"></section></header>`;
export const sidebar = `
<div style="background-color: #ffffff; height: 100vh">
    <svg
        role="img"
        width="216"
        height="590"
        aria-labelledby="loading-aria"
        viewBox="0 0 254 590"
        preserveAspectRatio="none"
    >
        <title id="loading-aria">Loading...</title>
        <rect x="0" y="0" width="100%" height="100%" clip-path="url(#clip-path)" style="fill: url('#fill')"></rect>
        <defs>
            <clipPath id="clip-path">
                <rect x="5" y="19" rx="3" ry="3" width="220" height="44" />
                <rect x="18" y="121" rx="0" ry="0" width="88" height="14" />
                <rect x="32" y="183" rx="0" ry="0" width="200" height="14" />
                <rect x="32" y="152" rx="0" ry="0" width="200" height="14" />
                <rect x="18" y="239" rx="0" ry="0" width="88" height="14" />
                <rect x="32" y="302" rx="0" ry="0" width="200" height="14" />
                <rect x="32" y="274" rx="0" ry="0" width="200" height="14" />
                <rect x="17" y="361" rx="0" ry="0" width="88" height="14" />
                <rect x="32" y="424" rx="0" ry="0" width="200" height="14" />
                <rect x="32" y="396" rx="0" ry="0" width="200" height="14" />
                <rect x="32" y="454" rx="0" ry="0" width="200" height="14" />
                <rect x="32" y="542" rx="0" ry="0" width="200" height="14" />
                <rect x="32" y="565" rx="0" ry="0" width="200" height="14" />
            </clipPath>
            <linearGradient id="fill">
                <stop offset="0.599964" stop-color="#ededed" stop-opacity="1">
                    <animate
                        attributeName="offset"
                        values="-2; -2; 1"
                        keyTimes="0; 0.25; 1"
                        dur="2s"
                        repeatCount="indefinite"
                    ></animate>
                </stop>
                <stop offset="1.59996" stop-color="#e3e3e3" stop-opacity="1">
                    <animate
                        attributeName="offset"
                        values="-1; -1; 2"
                        keyTimes="0; 0.25; 1"
                        dur="2s"
                        repeatCount="indefinite"
                    ></animate>
                </stop>
                <stop offset="2.59996" stop-color="#ededed" stop-opacity="1">
                    <animate
                        attributeName="offset"
                        values="0; 0; 3"
                        keyTimes="0; 0.25; 1"
                        dur="2s"
                        repeatCount="indefinite"
                    ></animate>
                </stop>
            </linearGradient>
        </defs>
    </svg>
</div>

`;
