import * as LDClient from 'launchdarkly-js-client-sdk';
import { useEffect, useState } from 'react';
import { FeatureFlag } from './feature-flags';

export type LDClientTypes = {
    context: LDClient.LDContext;
    envKey: string;
};

export const LaunchDarklyClientSide = async ({
    envKey,
    context,
}: LDClientTypes): Promise<LDClient.LDClient> => {
    const client = LDClient.initialize(envKey, context);

    return client.waitUntilReady().then(() => {
        localStorage.setItem('featureFlags', JSON.stringify(client.allFlags()));

        client.on('change', (changeSet: LDClient.LDFlagChangeset) => {
            const flagSet: LDClient.LDFlagSet = {};
            for (const key in changeSet) {
                flagSet[key] = changeSet[key].current;
            }
            const previous = JSON.parse(
                localStorage.getItem('featureFlags') || '{}',
            );
            localStorage.setItem(
                'featureFlags',
                JSON.stringify({ ...previous, ...flagSet }),
            );

            for (const flag in flagSet) {
                const event = new CustomEvent(`featureFlagChange:${flag}`, {
                    detail: flagSet[flag],
                });
                document.dispatchEvent(event);
            }
        });

        return client;
    });
};

export const useFeatureFlag = (flag: FeatureFlag) => {
    const [flagValue, setFlagValue] = useState(
        JSON.parse(localStorage.getItem('featureFlags') ?? '{}')[flag.key] ??
            flag.defaultValue,
    );

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const handleFlagChange = (e: any) => {
            setFlagValue(e.detail);
        };
        document.addEventListener(
            `featureFlagChange:${flag.key}`,
            handleFlagChange,
        );

        () => {
            document.removeEventListener(
                `featureFlagChange:${flag.key}`,
                handleFlagChange,
            );
        };
    }, [flag.key]);

    return flagValue;
};
