type NewrelicConfig = {
    accountId: string;
    trustKey: string;
    agentId: string;
    licenseKey: string;
    applicationId: string;
};

export const configureNewrelic = (newrelicConfig: NewrelicConfig) => {
    // configure the newrelic browser agent
    const config = window.NREUM || {};

    // Config to link newrelic browser to our newrelic account
    config.loader_config = {
        accountID: newrelicConfig.accountId,
        trustKey: newrelicConfig.trustKey,
        agentID: newrelicConfig.agentId,
        licenseKey: newrelicConfig.licenseKey,
        applicationID: newrelicConfig.applicationId,
    };

    // Config for the newrelic browser agent features
    config.init = {
        // Session replay captures dom snapshots during error traces with masked input values to make it secure
        // https://docs.newrelic.com/docs/browser/browser-monitoring/browser-pro-features/session-replay/
        session_replay: {
            enabled: true,
            block_selector: '',
            mask_text_selector: '*',
            sampling_rate: 100,
            error_sampling_rate: 100,
            mask_all_inputs: true,
            collect_fonts: true,
            inline_images: false,
            inline_stylesheet: true,
            mask_input_options: {},
        },
        // Distributed tracing adds request headers that the APM agent receives on the server side to link front end requests to back end requests
        // https://docs.newrelic.com/docs/distributed-tracing/concepts/introduction-distributed-tracing/
        distributed_tracing: { enabled: true },
        // Privacy settings for the agent
        // https://docs.newrelic.com/docs/browser/new-relic-browser/performance-quality/security-browser-monitoring/#cookies
        privacy: { cookies_enabled: true },
        // Requests to ignore in monitoring, e.g. if you don't care about google analytics requests the domain could be added here
        ajax: { deny_list: ['bam.nr-data.net'] },
    };

    // Config for the newrelic data collection endpoints
    config.info = {
        beacon: 'bam.nr-data.net',
        errorBeacon: 'bam.nr-data.net',
        licenseKey: newrelicConfig.licenseKey,
        applicationID: newrelicConfig.applicationId,
        sa: 1,
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    window.NREUM = config;
};
