const Breakpoints = {
    XS: '0',
    SM: '576px',
    MD: '758px',
    LG: '992px',
    XL: '1200px',
    XXL: '1440px',
    XXXL: '1600px',
};

/**
 * Custom layout events used to inform interested apps of layout changes
 */
const LAYOUT_EVENTS = {
    /**
     * Custom event to collapse the Sidebar. Only the Sidebar app should be
     * listenint to this event
     */
    COLLAPSE_SIDEBAR: 'collapse-sidebar',
    /**
     * Custom event to expand the Sidebar. Only the Sidebar app should be
     * listening to this event
     */
    EXPAND_SIDEBAR: 'expand-sidebar',
    /**
     * Custom event to show the cart (so far only on JobDetails page). Only the JobDetail app should be listening
     * to this event
     */
    SHOW_CART: 'show-cart',
    // These should only be used by the apps/GlobalElements/LayoutProvider
    /**
     * Custom event to let the GlobalElements app know that the Sidebar has been collapsed
     */
    SIDEBAR_COLLAPSED: 'sidebar-collapsed',
    /**
     * Custom event to let the GlobalElements app know that the Sidebar has been expanded
     */
    SIDEBAR_EXPANDED: 'sidebar-expanded',
    /**
     * Custom event to let the GlobalElements app know that the cart has been
     * shown. This is so that the LayoutProvider can update the layout grid
     */
    CART_SHOWN: 'cart-shown',
    /**
     * Custom event to let the GlobalElements app know that the cart has been
     * hidden. This is so that the LayoutProvider can update the layout grid
     */
    CART_HIDDEN: 'cart-hidden',
} as const;

// This should hold the expanded sidebar width by default, will be replaced based
// on conditions, same for the cartRootVar
const sidebarRootVariable = '--elements-sidebar-width';
const cartRootVariable = '--elements-cart-width';

// This should hold the collapsed Sidebar width value. Shouldn't ever change
const sidebarCollapsedRootVariable = '--elements-sidebar-width-collapsed';
// This should hold the expanded Sidebar width value. Shouldn't ever change
const sidebarExpandedRootVariable = '--elements-sidebar-width-expanded';
// Should always yield 216px, but can potentially change based on viewport size
const cartOpenLGRootVariable = '--elements-cart-width-open-lg';
// Should always yield 315px, but can potentially change based on viewport size
const cartOpenXLRootVariable = '--elements-cart-width-open-xl';

/**
 * Doesn't render anything. Acts as a central place for side effects that
 * cause layout changes using custom events.
 */
export class LayoutProvider {
    documentRef: Document;
    xxxLargeAndUp: boolean;
    sidebarExpandedWidth: string;
    sidebarCollapsedWidth: string;
    cartWidthLG: string;
    cartWidthXL: string;
    constructor() {
        this.documentRef = document;
        this.xxxLargeAndUp = window.matchMedia(
            `(min-width: ${Breakpoints.XXXL})`,
        ).matches;
        this.sidebarExpandedWidth = null;
        this.sidebarCollapsedWidth = null;
        this.cartWidthLG = null;
        this.cartWidthXL = null;

        this.overrideDocumentStyle = this.overrideDocumentStyle.bind(this);
        this.handleSidebarCollapsed = this.handleSidebarCollapsed.bind(this);
        this.handleSidebarExpanded = this.handleSidebarExpanded.bind(this);
        this.handleShowCart = this.handleShowCart.bind(this);
        this.handleHideCart = this.handleHideCart.bind(this);

        this.initialize();
    }

    initialize() {
        this.cartWidthLG = getComputedStyle(
            this.documentRef.documentElement,
        ).getPropertyValue(cartOpenLGRootVariable);

        this.cartWidthXL = getComputedStyle(
            this.documentRef.documentElement,
        ).getPropertyValue(cartOpenXLRootVariable);

        this.sidebarExpandedWidth = getComputedStyle(
            this.documentRef.documentElement,
        ).getPropertyValue(sidebarExpandedRootVariable);

        this.sidebarCollapsedWidth = getComputedStyle(
            this.documentRef.documentElement,
        ).getPropertyValue(sidebarCollapsedRootVariable);

        this.addEventListeners();
    }

    addEventListeners() {
        document.addEventListener(
            LAYOUT_EVENTS.CART_SHOWN,
            this.handleShowCart,
        );
        document.addEventListener(
            LAYOUT_EVENTS.CART_HIDDEN,
            this.handleHideCart,
        );
        document.addEventListener(
            LAYOUT_EVENTS.SIDEBAR_COLLAPSED,
            this.handleSidebarCollapsed,
        );
        document.addEventListener(
            LAYOUT_EVENTS.SIDEBAR_EXPANDED,
            this.handleSidebarExpanded,
        );
    }

    overrideDocumentStyle(property, value) {
        this.documentRef.documentElement.style.setProperty(property, value);
    }

    handleSidebarCollapsed() {
        this.overrideDocumentStyle(
            sidebarRootVariable,
            this.sidebarCollapsedWidth,
        );
    }

    handleSidebarExpanded() {
        this.overrideDocumentStyle(
            sidebarRootVariable,
            this.sidebarExpandedWidth,
        );
    }

    handleShowCart() {
        if (this.xxxLargeAndUp) {
            this.overrideDocumentStyle(cartRootVariable, this.cartWidthXL);
        } else {
            this.overrideDocumentStyle(cartRootVariable, this.cartWidthLG);
        }
    }

    handleHideCart() {
        this.overrideDocumentStyle(cartRootVariable, '0');
    }
}
