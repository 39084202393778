// Function to determine base URL based on environment
const getBaseUrl = (url: string): string => {
    const development = `http://dev.partstrader.us.com`;
    const production = `https://my.partstrader.us.com`;
    const test = `https://pp101.testpartstrader.us.com`;

    if (url.includes('dev')) return development;
    if (
        url.includes('repairer') &&
        !url.includes('testpartstrader') &&
        !url.includes('eod')
    )
        return production;
    if (url.includes('testpartstrader')) return test;

    return url; // Default to the current root URL if none of the above
};

export const buildPtusFrontendUrl = (path: string): string => {
    const { protocol, hostname, port } = window.location;
    const rootUrl = `${protocol}//${hostname}${port ? `:${port}` : ''}`;
    let baseUrl = getBaseUrl(rootUrl);

    if (window.location.href.includes('eod') && baseUrl.includes('repairer')) {
        baseUrl = baseUrl.replaceAll('repairer', '');
    }

    // Construct the final URL with the provided path
    return `${baseUrl}/${path}`;
};

export const buildRepairerFrontendUrl = (path: string): string => {
    const { protocol, hostname, port } = window.location;
    const rootUrl = `${protocol}//${hostname}${port ? `:${port}` : ''}`;

    // Construct the final URL with the provided path
    return `${rootUrl}/${path}`;
};
